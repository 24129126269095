/* MODAL ANIMATION */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInWindow {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeOutWindow {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--viewportHeight);
  background-color: var(--overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-overlay.show {
  animation: fadeIn 0.5s forwards ease-in;
}
.modal-overlay.hide {
  animation: fadeOut 0.5s forwards ease-out;
}
.modal-overlay.show > .modal-window {
  animation: fadeInWindow 0.5s forwards ease-in;
}
.modal-overlay.hide > .modal-window {
  animation: fadeOutWindow 0.5s forwards ease-out;
}

/* MODAL CONTENT */

.modal-overlay > .modal-window {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  width: 80%;
  max-height: var(--viewportHeight);
  background-color: var(--dark-blue);
  border-radius: 10px;
  border: 1px solid var(--box-shadow-heavy);
  box-shadow: 0 0 10px 0 var(--box-shadow);
  overflow: hidden;
}

@media (max-width: 767px) {
  .modal-overlay > .modal-window {
    width: 100%;
    height: var(--viewportHeight);
    max-height: 100%;
    border-radius: 0;
    border: none;
  }
}

/* MODAL CLOSE BUTTON */

.modal-overlay > .modal-window > .modal-close-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 24px;
  color: var(--pink);
}

.modal-overlay > .modal-window > .modal-close-button > span {
  font-family: 'Press Start 2P', cursive;
  font-size: 0.4rem;
  display: block;
  color: var(--star-color);
  margin-top: 1px;
}
