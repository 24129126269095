.legal-styles-wrapper {
  margin: 50px auto;
}

.legal-styles {
  position: relative;
  background-color: #fff;
  max-width: 768px;
  padding: 20px 20px 20px;
  margin: 0 auto 50px;
  border-radius: 80px;
  box-shadow: 0 0 10px var(--dark-blue);
}

.legal-styles header {
  position: absolute;
  top: -12px;
  left: 0;
  width: 100%;
  height: 100px;
}

.legal-styles h1 {
  text-align: center;
  font-family: 'Press Start 2P', cursive;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 20px;
  color: var(--dark-blue);
}

.legal-styles h2 {
  margin-top: 20px;
  font-weight: 900;
  color: var(--medium-blue);
  /* border-bottom: 1px solid var(--border-dark); */
}

.legal-styles h3 {
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  color: var(--star-color);
  font-style: italic;
}

.legal-styles b,
.legal-styles strong {
  font-weight: 700;
  color: var(--star-color);
}

.legal-styles em {
  font-style: italic;
}

.legal-styles p {
  font-size: 1rem;
  padding: 10px;
}

.legal-styles ul,
.legal-styles ol {
  padding: 10px;
  margin-left: 20px;
}

.legal-styles li {
  margin-bottom: 10px;
}
.legal-styles li:before {
  content: '•';
  color: var(--star-color);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.legal-styles a {
  color: var(--pink);
  text-decoration: underline;
}

.legal-styles footer {
  padding: 50px 25px 10px;
}

.legal-styles footer .links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
