/* CSS styles to work with leaflet divIcon */

.map-marker-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  width: 100px;
}

.map-marker-icon .image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.map-marker-icon .image img.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--border);
  /* box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5); */
}
.map-marker-icon .image img.icon {
  width: unset;
  height: unset;
  border: none;
  box-shadow: none;
  border-radius: 0;
  filter: drop-shadow(0 1px 0 rgba(255, 255, 255, 0.5));
}

.map-marker-icon.online .image::before {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  right: -2px;
  width: 14px;
  height: 14px;
  background-color: var(--green);
  border-radius: 50%;
  border: 2px solid var(--cream);
  z-index: 1000;
}
.map-marker-icon.online.dark .image::before {
  border-color: var(--medium-blue);
}

.map-marker-icon .label {
  padding: 0 10px;
  color: var(--white);
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  background-color: var(--medium-blue);
  border-radius: 10px;
  border: none;
  /* border: 1px solid var(--border); */
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.map-marker-icon.online .label {
  background-color: var(--green);
}
