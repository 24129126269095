/* @import "~leaflet/dist/leaflet.css"; */
/* @import "~leaflet.markercluster/dist/MarkerCluster.css"; */
/* @import "~leaflet.markercluster/dist/MarkerCluster.Default.css"; */
/* @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;900&display=swap'); */
/* @import-normalize; */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;600;900&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  /* LAYOUT */
  --viewportHeight: 100dvh; /* Fallback */
  --modalHeight: 80dvh; /* Fallback */
  /* COLORS */
  --dark-blue: #0d2847;
  --medium-blue: #133d71;
  --sun-color: #ff8a65;
  --moon-color: #c0c0c0;
  --star-color: #3da5a9;
  --white: #ffffff;
  --pink: hotpink;
  --cream: #f2e1ba;
  --green: #50a361;
  --purple: #553c9a;
  --red: #8b0000;
  --discord: #5a65ea;
  /* --background: #3f448e; */
  --background: #25305a;
  --border: #ffffff77;
  --border-dark: #133d7133;
  --overlay: #0d284777;
  --overlay-dark: #0d2847aa;
  --terminator-border: #ff8a65;
  --box-shadow: #133d7133;
  --box-shadow-heavy: #133d7199;
}

/* This fallback doesn't get triggered even though the browser has dhv */
@media (height: 100dvh) {
  :root {
    --viewportHeight: 100dvh;
    --modalHeight: 80dvh;
  }
}

/* body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
} */

/* html {
  height: -webkit-fill-available;
} */

html {
  background: var(--background);
  /* background: #25305a; */
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-exit {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.fade-enter {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
