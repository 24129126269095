.invalid {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 300px;
  transform: translate(-50%, -50%);
  font-family: 'Press Start 2P', cursive;
  font-size: 1.25rem;
  color: #ffffff;
  z-index: 1000;
}

.invalidText {
  position: relative;
}
