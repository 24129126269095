/* LEAFLET OVERRIDES */

.leaflet-left .leaflet-control {
  margin-left: 14px;
  margin-top: 14px;
}

@media (max-width: 767px) {
  .leaflet-left .leaflet-control {
    margin-left: 10px;
    margin-top: 10px;
  }
}

.leaflet-touch .leaflet-bar {
  border: 2px solid var(--border);
}

.leaflet-bar {
  border-radius: 20px;
}

.leaflet-bar a {
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: var(--medium-blue);
  background-color: var(--white);
  border-bottom: 1px solid var(--border-dark);
}
.dark .leaflet-bar a {
  background-color: var(--medium-blue);
  color: var(--white);
  border-color: var(--border);
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom: none;
}
