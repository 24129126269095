@keyframes loadingDots {
  0%,
  100% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Press Start 2P', cursive;
  font-size: 1.25rem;
  color: #ffffff;
  z-index: 1000;
}

.loadingText {
  position: relative;
}

.loadingDots::after {
  content: '';
  animation: loadingDots 2s infinite;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}
